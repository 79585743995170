import React, { FC, useMemo } from 'react';

import { Tooltip } from '@material-ui/core';

import TransparentPanel from '../../../../Core/Components/UiKit/Panel/TransparentPanel/TransparentPanel';
import { useOutsideClick } from '../../../../Core/Utils/useClickOutside';
import { IPropsRecapPopup } from './SharedMap.type';
import Separator from '../../../../Core/Components/UiKit/Separator/Separator';
import { useTranslation } from 'react-i18next';
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import { servicesPictograms } from '../../../Resource/constants/services.picto';

import * as Types from '../../Pages/SharedMap/SharedMap.type';
import * as MapTypes from '../../Data/Models/Map.type';

interface RecapWithPicto extends Types.IMarker {
  groupedLinks: {
    [key: string]: MapTypes.ILinkType[];
  };
}
const RecapPopup: FC<IPropsRecapPopup> = ({
  clearPopup,
  recapPopup,
  setFilters,
  setRecapPopup,
  showLegend,
}) => {
  const { t } = useTranslation(['map']);
  const ref = useOutsideClick(clearPopup);

  const recapWithPicto: RecapWithPicto = useMemo(() => {
    if (!recapPopup) return {} as RecapWithPicto;
    return {
      ...recapPopup,
      id: recapPopup?.id ?? '',
      groupedLinks: recapPopup?.links
        ? recapPopup.links.reduce((acc: any, link: any) => {
            const linkType = link.type.label;
            if (!acc[linkType]) {
              acc[linkType] = [];
            }
            acc[linkType].push(link);
            return acc;
          }, {})
        : [],
      valueSlots: recapPopup?.valueSlots
        ? recapPopup?.valueSlots
            .map((valueSlot: Types.IValueSlot) => {
              if (valueSlot.valueDef.label === 'Services') {
                const valueChoices = valueSlot.valueDef.valueChoices.map(
                  (valueChoice: MapTypes.IValueChoice) => {
                    const picto = servicesPictograms.find(
                      picto => picto.slug === valueChoice.value
                    );
                    return {
                      ...valueChoice,
                      picto: picto ? picto.icon : '',
                    };
                  }
                );
                return {
                  ...valueSlot,
                  valueDef: {
                    ...valueSlot.valueDef,
                    valueChoices,
                  },
                };
              } else {
                return valueSlot;
              }
            })
            .sort((a: Types.IValueSlot, b: Types.IValueSlot) =>
              a.valueDef.label.localeCompare(b.valueDef.label)
            )
        : [],
    };
  }, [recapPopup]);

  return (
    <div
      className={`fixed top-${
        showLegend ? '12' : 0
      } bg-transparent flex flex-col h-full right-0 overflow-hidden w-full md:w-96 z-1500 px-2 py-2 `}
      ref={ref}
    >
      <TransparentPanel />
      <div className='flex justify-between py-4 px-1 w-full text-white z-10'>
        <Tooltip title={t('map:header.panel.close') ?? ''}>
          <button onClick={clearPopup}>
            <FaIcon name='chevron-right' className='text-xl text-white' />
          </button>
        </Tooltip>
        <h1 className='text-white text-lg font-medium text-center px-2'>
          {recapWithPicto.name}
        </h1>
        <div />
      </div>

      <div className='z-10 flex flex-col overflow-y-auto'>
        <Separator type='horizontal' className='mb-2 z-10' />

        <div className='text-white'>
          <h2 className='text-base font-medium my-2'>
            {t('map:sidebar.recap.coordinates')} :
          </h2>
          <div>
            <p>{recapWithPicto?.address?.line1}</p>
            {recapWithPicto?.address?.line2 && (
              <p>{recapWithPicto?.address.line2}</p>
            )}
            {recapWithPicto?.address?.line3 && (
              <p>{recapWithPicto?.address.line3}</p>
            )}
            <p>
              {recapWithPicto?.address?.zip}, {recapWithPicto?.address?.city}
            </p>
          </div>
          {recapWithPicto?.valueSlots.length > 0
            ? recapWithPicto.valueSlots.map((valueSlot: Types.IValueSlot) => {
                return (
                  <div key={valueSlot.id}>
                    <h2 className='text-base font-medium my-2'>
                      {valueSlot.valueDef.label} :
                    </h2>
                    {valueSlot.valueDef.label === 'Services' ? (
                      <div className='flex flex-wrap items-center gap-3 mt-2'>
                        {valueSlot.valueDef.valueChoices.map(
                          (valueChoice: any) => {
                            return (
                              <div
                                className='bg-white p-2 rounded-md shadow-lg'
                                key={valueChoice.value}
                              >
                                <Tooltip
                                  title={valueChoice.label ?? ''}
                                  className='capitalize-first cursor-pointer'
                                  enterDelay={500}
                                >
                                  <img
                                    src={valueChoice.picto}
                                    alt={valueChoice.value}
                                    className='w-8 h-8'
                                  />
                                </Tooltip>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      <p className='capitalize-first'>{valueSlot.value}</p>
                    )}
                  </div>
                );
              })
            : null}
          {recapWithPicto?.groupedLinks &&
          Object.keys(recapWithPicto.groupedLinks).length > 0 ? (
            <>
              <Separator type='horizontal' className='my-4' />
              <div className='flex flex-col gap-y-2 items-start'>
                {Object.entries(recapWithPicto.groupedLinks).map(
                  ([type, links]: any, index: number) => (
                    <div key={index}>
                      <h2 className='text-base font-medium my-2 capitalize-first'>
                        {!recapWithPicto.isProject ? 'Activités' : type}
                      </h2>
                      <div className='w-full flex flex-col items-start gap-2'>
                        {links.map(
                          (link: MapTypes.ILinkType, index: number) => (
                            // Use p tag because button make a bug with firefox
                            <p
                              key={index}
                              onClick={() => {
                                setFilters(prev => ({
                                  ...prev,
                                  id: link.poi?.id,
                                }));
                                setRecapPopup(null);
                              }}
                              className='capitalize-first hover:font-bold transition-all ease-in-out cursor-pointer'
                            >
                              {link.poi?.name}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RecapPopup;
